<template>
  <section
    v-if="show"
    class="c-quick-register j-quick-register lcp-el"
    :data-theme="lang"
    da-expose-code="page-login-quik-expose-events"
    :class="[withFooter ? 'c-quick-register-footer' : '',
             from == 'detail' ? 'c-quick-register-detail' :'mshe-z-mask1',
             from != 'detail' && isDetailPgae ? 'c-quick-register-detail-fix j-qregister-dt-fix' : '',
             isNewMode || theme.registerImgSrc ? 'is-new-mode' : ''
    ]"
  >
    <div>
      <div
        v-show="!isShowSmallRegister || registerSuc"
        ref="closeBtn"
        v-enterkey
        class="quick__register-close" 
        @click.stop="close" 
      >
        <sui_icon_close_2_24px 
          size="10"
          color="#fff"
        />
      </div>
      <template v-if="!registerSuc">
        <transition-group
          :name="register_animate"
          tag="span"
        >
          <div
            v-show="!isShowSmallRegister"
            key="quick-big"
            class="show-register j-show-register show-register-es"
            :style="isNewMode || theme.backgroundColor ? {
              backgroundColor: theme.backgroundColor || registerImgConf.popup_colour || '#fff'
            } : {
              height: '4.67rem',
              background: `url(${ transformImg({ img: registerImgConf.register_img }) }) no-repeat`,
              backgroundSize: '100% 100%'
            }"
          >
            <img
              v-if="isNewMode || theme.registerImgSrc"
              :src="`${ transformImg({ img: theme.registerImgSrc || registerImgConf.register_img }) }`"
              width="100%"
              height="auto"
              alt=""
              style="min-height: 2.666667rem"
            />
            <div
              class="register-ctn"
              :style="theme.backgroundColor && {backgroundColor: theme.backgroundColor}"
            >
              <!-- <div class="register-ctn" :style="{ background: 'black'}"> -->
              <div class="register-content">
                <input
                  ref="registerInput"
                  type="text"
                  class="mshe-input j-quickemail-focus"
                  name="quickemail"
                  :placeholder="theme.inputText || registerImgConf.input_copy"
                  :style="{
                    color: theme.inputTextColor || registerImgConf.input_copy_colour,
                  }"
                  @blur="quickemailBlur"
                  @focus="quickemailFocus"
                  @click="handleQuickEmailClick"
                />
                <a
                  ref="quickregister_btn"
                  href="javascript:;"
                  class="j-quickregister-btn"
                  role="button"
                  :style="{
                    backgroundColor: theme.buttonBackgroundColor || registerImgConf.button_colour,
                    color: theme.buttonTextColor || registerImgConf.button_copy_colour
                  }"
                  @click="addQuickSignup()"
                >
                  <div
                    v-show="!loading"
                    ref="quick_showreg"
                    class="j-quick-showreg"
                    tabindex="0"
                    role="button"
                  >{{ language.SHEIN_KEY_PWA_33752 }}</div>
                  <s-loading
                    class="show-register__loading"
                    :show="loading"
                  />
                </a>
              </div>
              <p
                class="qiuck-error j-error-text"
                :tabindex="isQuickErrorShow ? 0 : -1"
              ></p>
              <div
                v-show="newPrivacyAbt.type == 'no'"
                class="c-privacy-check j-common-privacy-con j-privacy-check-register"
              >
                <div
                  class="privacy-item j-ga-quick-register"
                  :class="{'ccc-config': theme.policyTextColor}"
                  :style="{color: theme.policyTextColor || '#666'}"
                  data-gaflag="quickreg"
                >
                  <div
                    v-enterkey
                    class="privacy"
                    :class="siteUid"
                    tabindex="0"
                    role="button"
                    @click="handleNewPrivacyClick"
                    v-html="themeButtonText"
                  ></div>
                </div>
              </div>
              <div
                v-show="newPrivacyAbt.type == 'yes'"
                class="c-privacy-check j-common-privacy-con j-privacy-check-register"
              >
                <KrPrivacyCommon
                  v-if="siteUid == 'mkr'"
                  :language="language"
                  from="quickRegister"
                  class="privacy-item"
                  @change-handle="changeHandle"
                />
                <div
                  v-else
                  class="privacy-item j-ga-quick-register"
                  :class="{'ccc-config': theme.policyTextColor}"
                  :style="{color: theme.policyTextColor || '#666'}"
                  data-gaflag="quickreg"
                >
                  <s-radio 
                    v-model="isCheckedPrivacy"
                    gap="0.2rem"
                    class="privacy-radio"
                    :label="true"
                    :toggle="true" 
                    @change="handlePrivacyCheckboxClick"
                  />
                  <div
                    v-enterkey
                    class="privacy"
                    :class="siteUid"
                    tabindex="0"
                    role="button"
                    @click="handleNewPrivacyClick"
                    v-html="themeButtonText"
                  ></div>
                </div>
              </div>
              <RegisterSubscribe 
                :langText="language"
                :textColor="theme.policyTextColor"
                @change="handleSubscribe"
              />
              <p
                class="quick-text-error j-privacy-quick-tip mshe-hide"
                tabindex="0"
              >
                {{ language.SHEIN_KEY_PWA_15724 }}
              </p>
            </div>
          </div>

          <!-- 缩小状态 -->

          <div
            v-show="isNewMode && isShowSmallRegister"
            key="quick-small"
            class="show-register-small"
            :style="{
              backgroundColor: theme.descBackgroundColor || registerImgConf.popup_colour
            }"
            @click="showRegister"
          >
            <div
              v-enterkey
              class="quick__register-close" 
              @click.stop="close" 
            >
              <sui_icon_close_2_24px 
                size="10"
                color="#fff"
              />
            </div>
            <span
              class="content"
              :style="{
                color: theme.descTextColor || registerImgConf.left_colour_off
              }"
              tabindex="0"
            >
              {{ theme.descText || registerImgConf.left_copy }}
            </span>
            <a
              class="j-quick-register-btn"
              href="javascript:;"
              :style="{
                backgroundColor: theme.buttonBackgroundColor || registerImgConf.button_colour,
                color: theme.buttonTextColor || registerImgConf.button_copy_colour
              }"
              role="button"
            >{{ language.SHEIN_KEY_PWA_33752 }}</a>
          </div>
        </transition-group>
      </template>

      <div
        v-show="registerSuc"
        class="show-reg-suc j-register-success"
        :style="isNewMode || theme.backgroundColor ? {
          backgroundColor: theme.backgroundColor || registerImgConf.popup_colour || '#fff'
        } : {
          height: '4.67rem',
          background: `url(${transformImg({ img: theme.registerSuccessImgSrc || registerImgConf.register_success_img })}) no-repeat`,
          backgroundSize: '100% 100%'
        }"
      >
        <img
          v-if="isNewMode || theme.registerSuccessImgSrc"
          :src="`${ transformImg({ img: theme.registerSuccessImgSrc || registerImgConf.register_success_img }) }`"
          width="100%"
          height="auto"
          alt=""
        />
      </div>
    </div>
    <signup-email-validate
      :langText="language"
      :show="signupEmailValidateInfo.show"
      :opts="signupEmailValidateInfo.opts"
      @close="handleSignupEmailValidateClose"
    />
    <risk-validte
      ref="RiskValidate"
      :langText="language"
    />
  </section>
</template>

<script>
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent, nextTick } from 'vue'
import { enterkey } from 'public/src/pages/common/ada/index.js'
/* globals ,_quickRegisterEventCenter_,_gb_privacyEurCountry,gb_gdprRegister,menu_vue */
import * as attentive from '../attentive/index'
import {  formatNormalErrorMessage, combineNewPrivacyLanguage, handleNewPrivacyClick, loadWindowLoginLanguage } from '../../login/util'
import ExceptionReport from 'public/src/pages/common/RiskCommon/libs/ExceptionReport/index.js'
import { getMixPrivacyInfo } from '../../login/service'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import UAParser from 'ua-parser-js'
import GB_ga_register from './analysis'
import GB_bi_register from './analysis_bi'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import PopupAvoidLCP from 'public/src/pages/common/popupAvoidLCP'
import SignupEmailValidate from 'public/src/pages/login/components/SignupEmailValidate/index.vue'
import { patternEmail, template, transformImg, getLocalStorage, setLocalStorage } from '@shein/common-function'
import commonRiskValidate from 'public/src/pages/common/RiskCommon/mixin/commonRiskValidate.js'
import schttp from 'public/src/services/schttp/index'
import { sui_icon_close_2_24px } from '@shein-aidc/icon-vue3'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { geetestChallenge } from 'public/src/pages/common/RiskCommon/common/geetestChallenge.js'
import { reportSignupMarketing } from '@login/utils/loginAnalysis/signupMarketing.js'
import { quickRegisterSer } from 'public/src/pages/common/quick_register/service.js'
import { Metric_Quick_Register_Pop_Total, Metric_Quick_Register_Click_Result_Total } from '@login/utils/monitorLog.js'
import { loadTdLibJs } from 'public/src/pages/common/RiskCommon/libs/BlackBox/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import KrPrivacyCommon from 'public/src/pages/components/KrPrivacyCommon/index.vue'
import { postAddSubscribe } from 'public/src/pages/common/biz_helper/subscribe'
import RegisterSubscribe from './components/Subscribe.vue'
import { EventBus } from 'public/src/pages/common/event-bus.js'
import { riskVerifyChallenge } from '@login/common/riskVerifyManager.js'

daEventCenter.addSubscriber({ modulecode: '2-8' })

const { langPath, lang, LAYOUT, GDPR_SITE, templateType, SiteUID, WEB_CLIENT } = gbCommonInfo

const EVNET_NAME_SPACE = '.quick_register'

let gbDetailQkRegisterInit = undefined
let hasWatchAppRoute = false

let scrollTimer = null
let isSendAnalysis = false // 是否已经发送过埋点

// 初始化显示快速注册小模块弹窗
class InitScroll {
  constructor (hide, dom) {
    this.t = 0
    this.p = 0
    this.hide = hide
    this.dom = dom

    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll () {
    this.p = this.dom.pageYOffset || document.documentElement.scrollTop
    if (this.t < (this.p - 2)) {
      this.hide()
    }
    window.gbRegisterModule.updateShowPop()
    // eslint-disable-next-line  no-return-assign
    Promise.resolve().then(() => this.t = this.p)
  }

  bind () {
    this.dom.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  unbind () {
    this.dom.removeEventListener('scroll', this.handleScroll)
  }
}

export default defineComponent({
  name: 'QuickRegister',
  components: {
    SignupEmailValidate,
    sui_icon_close_2_24px,
    SRadio,
    KrPrivacyCommon,
    RegisterSubscribe,
    SLoading,
  },
  directives: {
    enterkey,
  },
  mixins: [ commonRiskValidate ],
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lang,
      WEB_CLIENT,
      siteUid: SiteUID,
      language: {},
      popupAvoidLCP: null,
      firstCouponSite: null,
      show: false,
      isDetailPgae: false,
      withFooter: false,
      withFixed: true,
      enabledClick: true,
      isPrivacyShow: true,
      isHasPrivacy: GDPR_SITE.includes(lang) || lang == 'us',
      loading: false,
      registerSuc: false,
      templateType,
      isShowSmallRegister: false,
      isFocus: false, // 防止输入框聚焦时页面抖动 收缩快速注册弹窗
      register_animate: '',
      prevSatus: '',
      theme: {}, // ccc样式
      isFetchData: false,
      newPrivacyAbt: { type: '', loading: true, origin: '' },
      isCheckedPrivacy: false,
      krIsSubscrip: false,
      isQuickErrorShow: false,
      signupEmailValidateInfo: {
        show: false,
        opts: {},
      },
      checkSubscribe: false,
      countryId: '',
      links: [],
      clickHandler: null,
      scrollInstance: null,
      unwatch: null,
    }
  },
  computed: {
    registerImgConf () {
      return this.firstCouponSite && this.firstCouponSite.registerImgConf || {}
    },
    // 根据左侧文案有无配置判断是否使用新弹窗逻辑
    isNewMode () {
      return this.registerImgConf.left_copy || this.theme.descText
    },
    themeButtonText () {
      const text = this.theme.buttonText || this.registerImgConf.button_copy || ''
      let str = this.language.SHEIN_KEY_PWA_17436
      const type = this.newPrivacyAbt.type
      if (type == 'yes') {
        // str = this.combineNewPrivacyLanguage(this.language.SHEIN_KEY_PWA_17944)
        str = this.combineNewPrivacyLanguage({ from: 'quick_register_pop', abt: 'yes', language: this.language, action: 'signup', location: 'quick_register_pop' })
      }
      if (type == 'no') {
        // str = this.combineNewPrivacyLanguage(this.language.SHEIN_KEY_PWA_17946)
        str = this.combineNewPrivacyLanguage({ abt: 'no', language: this.language, action: 'signup', location: 'quick_register_pop' })
      }
      if (text) {
        return this.template(text, str)
      }
      return str
    }
  },
  mounted () {
    window.gbRegisterModule = this // TODO: 待优化，异步组件导致需重新赋值
    this.initData()
    document.addEventListener('click', this.updateShowPop)
    document.addEventListener('touchstart', this.updateShowPop)
    document.addEventListener('touchmove', this.updateShowPop)
    document.addEventListener('touchend', this.updateShowPop)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.updateShowPop)
    document.removeEventListener('touchstart', this.updateShowPop)
    document.removeEventListener('touchmove', this.updateShowPop)
    document.removeEventListener('touchend', this.updateShowPop)
    window.removeEventListener(`scroll${EVNET_NAME_SPACE}`, this.handleScrollEvent)

    this._.data.unwatch?.()

    if (this.lang === 'us' && !this.countryId && typeof _quickRegisterEventCenter_ != 'undefined') {
      _quickRegisterEventCenter_.off('us-privacy-ip-quickregister', this.usPrivacyIPQuickRegister)
    }

    this.links.forEach(function(link) {
      link.removeEventListener('click', this.clickHandler)
    })
    
    // Vue 3 不支持 $once('hook:beforeDestroy')，移至此处
    if (this.popupAvoidLCP) {
      this.popupAvoidLCP.destroy && this.popupAvoidLCP.destroy()
      this.popupAvoidLCP = null
    }
  },
  unmounted() {
    this._.data.scrollInstance?.unbind()
  },
  methods: {
    transformImg,
    template,
    combineNewPrivacyLanguage,
    handleNewPrivacyClick,
    changeHandle ({ privacy, subscrip }) {
      this.krIsSubscrip = subscrip
      this.isCheckedPrivacy = privacy
    },
    handleTAndCProtocol () {
      window.location.href = `${langPath}/Terms-and-Conditions-a-399.html`
    },
    handleSubscribe (val) {
      this.checkSubscribe = val
    },
    // 曝光事件
    exposeEvents () {
      setTimeout(() => {
        const daEventExpose = daEventCenter.getExposeInstance()
        daEventExpose.subscribe({
          once: true,
          keycode: 'page-login-quik-expose-events`2-8-60|2-8-62',
        })
        Metric_Quick_Register_Pop_Total({ login_from: SaPageInfo?.page_name || '-', sub_site: SiteUID || '' })
      }, 1000)
    },
    showMiniRegister () {
      if (this.isFocus || this.isShowSmallRegister || this.registerSuc) {
        return
      }
      this.register_animate = 'slide-fade'
      this.isShowSmallRegister = true
      GB_bi_register.biExposeEvent('hide')
    },
    initData () {
      if (isLogin()) { // 已登录，不展示快速登录
        this.loadDone()
        return
      }
      // cod订单确认页,美国站新人频道页 不需要快速注册弹窗
      if ((typeof window.notNeedQuickRegister != 'undefined') && window.notNeedQuickRegister) {
        this.loadDone()
        return
      }
      // 购物车及分享页面不显示快速注册
      if ((/cart$/.test(location.pathname)) || (/share$/.test(location.pathname)) || (/signup_fast$/.test(location.pathname)) || (/guest_first_address$/.test(location.pathname)) || (/buyapowa/.test(location.pathname)) || (/refer-a-friend/.test(location.pathname))) {
        this.loadDone()
        return
      }
      this.initActionInDetail()
      this.getNewPrivacyAbt()
      this.fetchData().then( async () => {
        nextTick(() => {
          this.bindEvent()
          // 初始化显示小模块快速注册滚动
          if ((this.from !== 'detail') && this.isNewMode) {
            setTimeout(() => {
              this.bindScroll()
            }, 0)
          }
        })
        if (this.check()) {
          await this.initVariables() 
          this.loadQueue()
          return
        }
        this.loadDone()
      }).catch(() => this.loadDone())
      setTimeout(() => {
        if(typeof window !== 'undefined' && !window._fmOpt?.__blackbox){
          loadTdLibJs()
        }
      }, 300)
    },
    bindScroll () {
      const quickRegisterScrollElements = document.querySelectorAll('.j-quick-register-scroll')
      const scrollTarget = quickRegisterScrollElements.length ? quickRegisterScrollElements[0] : window

      this.scrollInstance = new InitScroll(this.showMiniRegister, scrollTarget)
      this.scrollInstance.bind()
    },
    // 切换快速注册 大小弹层
    showRegister () {
      this.isShowSmallRegister = false
      GB_ga_register.openPopUps()
      GB_bi_register.biClickEvent('hide')
      GB_bi_register.biExposeEvent('show')
      if (this.newPrivacyAbt.type == 'yes') {
        daEventCenter.triggerNotice({
          daId: '2-8-12',
          extraData: { register_scene: 2, gaScenes: 'QuickRegister', sensorsScenes: '快速弹窗' }
        })
      }
    },
    initVariables () {
      const saInfo = (function () {
        if (typeof SaPageInfo !== 'undefined' && Object.keys(SaPageInfo).length) {
          return SaPageInfo
        }
        return {}
      })()
      const from_tabbar_new = location.href.indexOf('from_tabbar_new') > -1
      const hasFooterPageId = [1, 115] // 首页、个人首页
      const isUserIndex = typeof window !== 'undefined' && window.location.pathname.includes('user/index') // 个人中心
      if (hasFooterPageId.includes(saInfo.page_id) || isUserIndex) {
        this.withFooter = true
      }

      if (saInfo.page_id === 41 && from_tabbar_new) { // 来自活动页并且是来顶部tabbar的点击
        this.withFooter = true
      }

      if (typeof _gb_app_ === 'undefined') return
      // 处理商品选购页的router流程
      const routeName = window._gb_app_.$route.name || ''
      const hasFooterRouteName = ['page_category']

      if (hasFooterRouteName.includes(routeName)) {
        this.withFooter = true
      }

      if (saInfo?.page_name?.indexOf('page_store_') > -1) { //品牌店铺
        this.withFooter = true
      }

      if (routeName === 'page_goods_detail') { // 判断是否为详情页
        this.isDetailPgae = true
      }

      if (hasWatchAppRoute) {
        return
      }

      // quick_register 存在没有挂在到_gb_app_上的场景
      const gbAppRouterWatchCb = (route) => {
        const { from_tabbar_new = '' } = _gb_app_.$route.query
        const { name } = route
        nextTick(()=>{
          const pageNames = ['page_category', 'config_index', 'UserIndex']
          this.withFooter = pageNames.includes(name)
          if (name == 'config_activity' && from_tabbar_new == 1) {
            this.withFooter = true
          }
          if (name == 'page_store') { // 店铺页面
            // 品牌店铺下面有tab 需要加50px
            nextTick(()=>{
              if(SaPageInfo?.page_name?.indexOf('page_store_') > -1){
                this.withFooter = true
              }
            })
          }
          this.isDetailPgae = name === 'page_goods_detail'
          // abt隐藏不展示
          if (this.abtHide) return
          if (name === 'cart' || name === 'guest_first_address' || name === 'buyapowa' || name === 'FriendshipCoupon' || name === 'FriendshipCouponReceive') { // 单页面路由进入购物车隐藏快速注册
            this.show = false
          } else {
            this.recoverQuickStatus()
          }
        })
      }
      this.unwatch = _gb_app_.$watch('$route', gbAppRouterWatchCb)
      hasWatchAppRoute = true
    },
    // 初始来自商品详情页的特殊操作
    initActionInDetail () {
      if (this.from !== 'detail') return
      // 获取一个详情页定制的实例指向，方便同步操作
      gbDetailQkRegisterInit = true
      const hide_bar = getLocalStorage('hide_download_bar')
      const hide_bar_dt = getLocalStorage('hide_download_bar_dt')
      if (hide_bar && !hide_bar_dt) {
       
        // 如果全局弹窗已关闭但是详情页的内嵌注册未关闭，需要控制显示曝光埋点
        window.addEventListener(`scroll${EVNET_NAME_SPACE}`, this.handleScrollEvent)
      }
    },
    handleScrollEvent() {
      if (scrollTimer) {
        clearTimeout(scrollTimer)
      }
      scrollTimer = setTimeout(() => {
        const quickRegisterElement = document.querySelector('.j-quick-register')
        const addToBagWrapperElement = document.querySelector('.j-btn-add-to-bag-wrapper')
        if (!isSendAnalysis && quickRegisterElement && addToBagWrapperElement) {
          const registerHeight = quickRegisterElement.getBoundingClientRect().top + addToBagWrapperElement.clientHeight
          if (registerHeight < window.innerHeight) {
            GB_ga_register.autoPopUps()
            GB_bi_register.biExposeEvent('show')
            isSendAnalysis = true
          }
        }
      }, 200)
    },
    updateShowPop () {
      this.initVariables()
      const routeName = window?._gb_app_?.$route?.name || ''
      if (routeName === 'cart' || routeName === 'guest_first_address' || routeName === 'buyapowa' || routeName === 'FriendshipCoupon' || routeName === 'FriendshipCouponReceive') { // 单页面路由进入购物车隐藏快速注册
        this.show = false
      }
    },
    check () {
      const hide_bar = getLocalStorage('hide_download_bar')
      const hide_bar_dt = getLocalStorage('hide_download_bar_dt')

      // 来自详情页定制的特殊快速注册的显示判断
      if (this.from === 'detail') {
        // 全局弹窗未关闭，不显示内嵌定制的快速注册
        if (!hide_bar) return false
        // 内嵌定制的快速注册已经关闭过，缓存时间不显示
        if (hide_bar_dt) return false
        return true
      }

      if (window.noNeedQuickRegister && window.noNeedQuickRegister === true) return

      // 以下为非定制的快速注册显示判断
      if (!['layout', 'app'].includes(LAYOUT)) { // 非layout/app模板
        return false
      }

      if (hide_bar) { // 关闭过快速注册
        return false
      }

      // 非注册页和学生活动页
      if (/user(\/auth)?\/login$/.test(location.pathname) || /student$/.test(location.pathname)) {
        return false
      }

      // if (gbCommonInfo.isInitGaPageview) { // ga埋点文件是否初始化完成
      //   GB_ga_register.autoPopUps()
      // } else {
      //   appEventCenter && appEventCenter.on('after_page_view', function () {
      //     GB_ga_register.autoPopUps()
      //   })
      // }
      const popupType = this.isShowSmallRegister ? 'hide' : 'show'
      GB_bi_register.biExposeEvent(popupType)

      return true
    },
    bindEvent () {
      // GDPR
      const { countryId = '' } = UserInfoManager.getSync({ key: 'Country', actionType: 'quickRegister/bindEvent' }) || {}
      this.countryId = countryId
      // us站点并且非欧洲ip站点
      if (this.lang === 'us' && !_gb_privacyEurCountry.indexOf(parseInt(countryId, 10)) > -1) {
        this.isPrivacyShow = false
        return
      }

      // us站点并且没有设置过pwa_countryid
      if (this.lang === 'us' && !countryId && typeof _quickRegisterEventCenter_ != 'undefined') {
        _quickRegisterEventCenter_.on('us-privacy-ip-quickregister', this.usPrivacyIPQuickRegister)
      }
    },
    usPrivacyIPQuickRegister(data) {
      const isEurIp = _gb_privacyEurCountry.indexOf(parseInt(data, 10)) > -1
      if (!isEurIp) {
        this.isPrivacyShow = false
      }
    },
    // 单纯关闭，提供给外部调用
    rawHide () {
      if (this.from !== 'detail' && gbDetailQkRegisterInit) {
        this.show = false
      }
      this.show = false
      //   this.from !== 'detail' && window._GB_PopUpModalEventCenter_.next('quickRegister')
    },
    // 恢复快速注册的上一次状态，用于单页面应用从购购物路由回到其他路由
    async recoverQuickStatus () {
      if (this.from === 'detail') return
      if (!isLogin() && parseInt(getLocalStorage('hide_download_bar'), 10) !== 1) {
        if(!this.isFetchData){
          await this.initData()
          setTimeout(() => {
            this.show = true
          }, 0)
          return
        } 
        this.show = true
      } else {
        this.show = false
      }
    },
    close () {
      GB_ga_register.closePopUps()

      if (!this.registerSuc) {
        const popupType = this.isShowSmallRegister ? 'hide' : 'show'
        GB_bi_register.biCloseQuickRegister(popupType)
      }

      // 关闭全局弹窗的时候显示详情页的内嵌快速注册
      if (this.from !== 'detail' && gbDetailQkRegisterInit) {
        this.isShowSmallRegister = false
        this.show = true
      }
      // 设置详情页自己特殊的快速注册快速cookie
      if (this.from === 'detail') {
        setLocalStorage({ key: 'hide_download_bar_dt', value: 1, expire: 60 * 60 * 24 * 10 * 1000 })
      }
      this.show = false
      setLocalStorage({ key: 'hide_download_bar', value: 1, expire: 60 * 60 * 24 * 10 * 1000 })
      this.from !== 'detail' && window._GB_PopUpModalEventCenter_.next('quickRegister')
    },
    quickemailBlur () {
      appEventCenter.emit('quickRegisterDialogEvent', { type: 'blur' })
      const emailElement = document.querySelector('input[name="quickemail"]')
      let email = ''
      if (emailElement) {
        email = emailElement.value.trim()
      }
      const errortip = document.querySelector('.j-error-text')
      this.isFocus = false

      if (!email.length) {
        errortip.innerHTML = ''
        this.isQuickErrorShow = false
      } else if (email.length && !patternEmail({ email, signUp: true })) {
        errortip.innerHTML = this.language.SHEIN_KEY_PWA_15594
        this.isQuickErrorShow = true
      } else {
        errortip.innerHTML = ''
        this.isQuickErrorShow = false
      }
      const containers = document.querySelectorAll('.j-mshe-container')
      containers.forEach(function(container) {
        container.style.pointerEvents = ''
      })
    },
    quickemailFocus () {
      this.isFocus = true
      const containers = document.querySelectorAll('.j-mshe-container')
      containers.forEach(function(container) {
        container.style.pointerEvents = 'none'
      })
      if (/(iPhone|iPad|iPod|iOS)/i.test(UAParser().os.name) && UAParser().os.version == '10.2.1') {
        setTimeout(function () {
          document.querySelector('.j-quick-register').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        }, 200)
      }
    },

    addQuickSignup () {
      this.quickregisterBtn()
    },

    quickregisterBtn () {
      if (!this.enabledClick) return false
      var self = this
      const emailElement = document.querySelector('input[name="quickemail"]')
      let email = ''
      if (emailElement) {
        email = emailElement.value.trim()
      }
      const errortip = document.querySelector('.j-error-text')
      GB_bi_register.biClickEvent('show', this.isCheckedPrivacy ? 1 : 0)
      if (!email.length) {
        GB_ga_register.register(0, 'Please fill in the mailbox')
        GB_bi_register.biRegisterResult('failure', 'empty_email', 'show')
        errortip.innerHTML = self.language.SHEIN_KEY_PWA_15589
        self.isQuickErrorShow = true
      } else if (!patternEmail({ email, signUp: true })) {
        GB_ga_register.register(0, 'Mailbox validation failure')
        GB_bi_register.biRegisterResult('failure', 'incorrect_email_format', 'show')
        errortip.innerHTML = self.language.SHEIN_KEY_PWA_15594
        self.isQuickErrorShow = true
      } else {
        this.enabledClick = false
        this.loading = true
        if (self.isHasPrivacy && self.isPrivacyShow) {
          const tips = document.querySelectorAll('.j-privacy-quick-tip')
          tips.forEach(function(tip) {
            tip.style.display = 'none'
          })
        }
        const userData = { email }
        userData.clause_flag = 0
        if (this.newPrivacyAbt.type == 'yes') {
          if (!this.isCheckedPrivacy) {
            self.loading = false
            self.enabledClick = true
            if (SiteUID == 'mkr') {
              SToast(this.language.SHEIN_KEY_PWA_31878)
            } else {
              errortip.innerHTML = this.language.SHEIN_KEY_PWA_17949
              self.isQuickErrorShow = true
            }
            GB_ga_register.register(0, 'Please agree to our Privacy Policy')
            GB_bi_register.biRegisterResult('failure', 'other', 'show')
            return
          }
          userData.clause_flag = 1
          userData.clause_agree = 1
        }
        window._GB_DeviceFingerPrint.callFunc(() => {
          this.requstQuickRegister(userData)
        })
      }
    },
    async requstQuickRegister (userData) {
      this.loading = true
      var self = this
      var email = userData.email
      let errortip = document.querySelector('.j-error-text')
      let success = document.querySelector('.j-register-success')

      const isUserMediumSite = gbCommonInfo.isUserMediumSite // 是否是用户中台一期
      userData.is_whole_compare = isUserMediumSite ? 1 : 0 // 全局拦截，包括社交账号
      userData.locationId = this.newPrivacyAbt.origin == 'mix' ? (this.locationId || '') : ''
      this.checkSubscribe ? Object.assign(userData, { checkSubscribe: this.checkSubscribe }) : ''

      const res = await geetestChallenge(quickRegisterSer, userData, 'register')
      if (!res) {
        this.loading = false
        this.enabledClick = true
        GB_bi_register.biRegisterResult('failure', 'network_error', 'show')
        return
      }
      const { apiResData = {}, status } = res
      if (status == 'error') {
        errortip.innerHTML = this.language.SHEIN_KEY_PWA_16274
        self.isQuickErrorShow = true
        this.enabledClick = true
        this.loading = false
        return
      }
      if (status == 'close') {
        this.loading = false
        this.enabledClick = true
        return
      }
      const data = apiResData || {}
      const { code } = data || {}
      this.enabledClick = true
      Metric_Quick_Register_Click_Result_Total({ login_from: SaPageInfo?.page_name || '-', sub_site: SiteUID || '', result: code == 0 ? 'success' : 'failed', error: code  })
      // 风控检测
      if (this.detectRiskChalleage(data, { riskCode: 402908, params: userData, message_type: 'register_confirm', scene: 'register' }, ({ type, params }) => {
        if (type == 'close') {
          this.loading = false
          this.enabledClick = true
          return
        }
        this.requstQuickRegister(params)
      })) {
        this.loading = false
        return
      }

      if (data.code == 0) {
        reportSignupMarketing('Email')
        if (this.siteUid == 'mkr' && this.krIsSubscrip) {
          postAddSubscribe({
            subscribe_type: '1',
            alias: userData.email,
            scene_type: '1',
          })
        }
        if (data.info && data.info.member) {
          const userInfo = ['account_type', 'add_time', /*'email', */'login_time_last', 'member_id', 'modifyPassword', 'nickname', 'token']
          // eslint-disable-next-line require-atomic-updates
          gbCommonInfo.user = {}
          userInfo.forEach(key => {
            gbCommonInfo.user[key] = data.info.member[key]
          })
          // eslint-disable-next-line require-atomic-updates
          gbCommonInfo.user.email = '1'
        }

        GB_ga_register.register(1, 'Success')
        GB_bi_register.biRegisterResult('success', '', 'show')
        if (self.withFixed) {
          success.classList.add('animated', 'slideInUp')
        }
        if (GDPR_SITE.indexOf(lang) > -1 && typeof gb_gdprRegister !== 'undefined') {
          gb_gdprRegister('Subscribe-register', 'AgreePrivacyPolicy')
        }
        if (attentive.getSmsAbtResult()) {
          self.rawHide()
          if( SiteUID === 'rwmus' )attentive.showAttentiveA()
        } else {
          self.changeSuccessData()
        }
        // 更新category人群信息
        window.appEventCenter?.emit('refreshCategoryCrowdInfo')

        // 注册成功，如果当前处在详情页，需要把详情页内嵌的快速注册隐藏
        if (self.from !== 'detail' && typeof gbDetailQkRegisterInit !== 'undefined') {
          self.changeSuccessData()
        }
        window?.sessionStorage?.removeItem('mutiplePartPrivacy')
        setTimeout(function () {
          var hi = 'Hello '
          var username = userData.email.match(/(.+)@.+/)[1]
          if (window.gb_index_vue && menu_vue) {
            menu_vue.cateusername = hi + username
            menu_vue.status.isLogin = true
          }
          if (window.gb_setting) {
            // setting_vue.isLogin = true
          }
          if (typeof appEventCenter != 'undefined' && data.info && data.info.member) {
            appEventCenter.emit('initMenuLoginStatus', data.info.member)
          }
          EventBus.emit('init-userIndex-userData', data)
        }, 300)
        window._abt_server_provider && window._abt_server_provider.clear && window._abt_server_provider.clear()
        ExceptionReport.login()
        return
      }
      this.loading = false
      if(code == 402922){
        riskVerifyChallenge(data, userData, ({ paramsData, type }) => {
          if(type == 'error') {
            this.loading = false
            this.enabledClick = true
            SToast(this.langText?.SHEIN_KEY_PWA_14899)
            return
          }
          if(type == 'close') {
            this.loading = false
            this.enabledClick = true
            return
          }
          this.requstQuickRegister(paramsData)
        })
        return
      }

      if (data.code === -405) ExceptionReport.geetest()

      if (data.code == 400569) {
        self.signupEmailValidateInfo.show = true
        self.signupEmailValidateInfo.opts.email = userData.email
        self.signupEmailValidateInfo.opts.cb = (emialCode) => {
          userData.check_register_email_code = emialCode
          self.requstQuickRegister(userData)
        }
        return
      }
      if (data.code == 400503) {
        GB_ga_register.register(0, 'EmailHasBeenUsed')
        GB_bi_register.biRegisterResult('failure', 'registered_email', 'show')
        if (lang === 'us') {
          errortip.innerHTML = self.language.SHEIN_KEY_PWA_16635
          self.quickLoginTips(errortip, { email })
        } else {
          errortip.innerHTML = self.language.SHEIN_KEY_PWA_15590
        }
      } else if (data.code == 400517 || data.code == 400522) {
        GB_ga_register.register(0, data.msg)
        GB_bi_register.biRegisterResult('failure', 'IP_limit_error', 'show')
        errortip.innerHTML = self.language.SHEIN_KEY_PWA_16683
      } else if (data.code == 100102) {
        GB_ga_register.register(0, 'Mailbox validation failure')
        GB_bi_register.biRegisterResult('failure', 'incorrect_email_format', 'show')
        errortip.innerHTML = self.language.SHEIN_KEY_PWA_15594
      } else if (data.code == 400542) { // 与社交媒体账号冲突
        GB_ga_register.register(0, 'EmailHasBeenUsed')
        GB_bi_register.biRegisterResult('failure', 'registered_email', 'show')
        errortip.innerHTML = self.language.SHEIN_KEY_PWA_16401
      } else {
        const errTip = formatNormalErrorMessage(data)

        GB_ga_register.register(0, data.msg)
        GB_bi_register.biRegisterResult('failure', 'other', 'show')
        errortip.textContent = errTip
      }
      self.isQuickErrorShow = true
      self.loading = false
      self.enabledClick = true
    },
    changeSuccessData: function () {
      this.registerSuc = true
      const masks = document.querySelectorAll('.j-quick-register-mask')
      masks.forEach(function(mask) {
        mask.classList.add('mshe-hide')
      })
    },
    quickLoginTips ($html, data) {
      this.clickHandler = this.clickLogInNowEvent.bind(this, data)
      this.links = $html.querySelectorAll('a')
      this.links.forEach(function(link) {
        link.addEventListener('click', this.clickHandler)
      })
    },
    clickLogInNowEvent (data) {
      SHEIN_LOGIN.show({ show: true, email: data.email, cb: this.getBindInfo })
    },
    getBindInfo (res) {
      EventBus.emit('init-userIndex-userData', res)
      // 隐藏掉快速注册
      this.rawHide()
      if ( SiteUID !== 'rwmus') return
      attentive.checkAttenBindInfo.then((data) => {
        const { show } = data || {}
        if (show) attentive.showAttentiveA()
      })
    },
    async fetchData () {
      const { FastRegisterPopup } = await getUserAbtData()
      const abtBranch = FastRegisterPopup?.param || ''

      const { language = {}, info, theme } = await schttp({ url: '/api/auth/quickRegisterInit/get', params: { abtBranch } }).catch(() => ({}))

      this.language = language
      this.firstCouponSite = info
      this.setTheme(theme.data && theme.data[0])
      if(theme?.data[0]){
        this.isFetchData = true
      }
    },
    // 设置注册弹窗风格样式
    setTheme (theme) {
      if (!theme) return
      if (theme.content && theme.content[0] && theme.content[0].content && theme.content[0].content.props && theme.content[0].content.props.items && theme.content[0].content.props.items[0]) {
        this.theme = theme.content[0].content.props.items[0]
        this.popupAvoidLCP  = new PopupAvoidLCP({
          vueInstance: this,
          ctrlVariableName: 'show',
          imgList: [(this.theme.registerImgSrc && this.transformImg({ img: this.theme.registerImgSrc })) || '', (this.registerImgConf.register_img && this.transformImg({ img: this.registerImgConf.register_img })) || ''],
        })
      }
    },
    loadDone () {
      // 状态初始化完成，不显示
      this.from !== 'detail' && window._GB_PopUpModalEventCenter_.done('quickRegister')
    },
    async loadQueue () {
      const { Quickregclosed } = await getUserAbtData()
      if (Quickregclosed?.param == 'off') {
        this.abtHide = true
        this.loadDone()
        return
      }

      const self = this

      if (self.from !== 'detail') { // 只有来自全局弹窗的注册才加入全局待展示队列
        // 添加进去待显示队列
        window._GB_PopUpModalEventCenter_.pushQueue('quickRegister', () => {
          // 在出现之前有可能已经登录了，已登录，不再弹快速注册弹窗
          if (isLogin()) {
            window._GB_PopUpModalEventCenter_.next('quickRegister')
            return
          }
          this.exposeEvents()
          loadWindowLoginLanguage() // 加载登录多语言
          self.show = true
          GB_ga_register.autoPopUps()
          if (this.newPrivacyAbt.type == 'yes') {
            daEventCenter.triggerNotice({
              daId: '2-8-12',
              extraData: { register_scene: 2, gaScenes: 'QuickRegister', sensorsScenes: '快速弹窗' }
            })
          }
        })
        return
      }
    },
    handlePrivacyCheckboxClick () {
      daEventCenter.triggerNotice({
        daId: '2-8-11',
        extraData: { register_scene: 2, gaScenes: 'QuickRegister', sensorsScenes: '快速弹窗' }
      })
    },
    async getNewPrivacyAbt () {
      const { newPrivacy } = await getUserAbtData()
      this.newPrivacyAbt.loading = false 
      const param = newPrivacy?.param || ''
      if (param == 'yes') {
        this.newPrivacyAbt.type = 'yes'
        return
      }
      if (param == 'mix') {
        this.newPrivacyAbt.origin = 'mix'
        this.requestMixPrivacy()
        return
      }
      this.newPrivacyAbt.type = param || 'off'
    },
    // 获取一站多合规请求
    async requestMixPrivacy () {
      const { id, privacyType } = await getMixPrivacyInfo()
      this.locationId = id || ''
      if (privacyType == '1') {
        this.newPrivacyAbt.type = 'yes'
        return
      }
      this.newPrivacyAbt.type = 'no'
    },
    handleQuickEmailClick () {
      GB_ga_register.editEmail()
    },
    handleSignupEmailValidateClose () {
      this.signupEmailValidateInfo.show = false
      this.loading = false
      this.enabledClick = true
    },
  },
})
</script>

<style lang="less" scoped>
@import './style/quick_register.less';
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type, declaration-no-important  */
.c-quick-register {

    /* 快速注册动画 */
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to {
        opacity: 0;
    }

    position: fixed;
    bottom: 0rem;
    width: 10rem;
    text-align: center;
    margin: 0 auto;
    &.c-quick-register-detail {
        position: relative;
        bottom: auto;
        height: 5rem;
        overflow: hidden;
        margin-bottom: .27rem;
        .quick__register-close{
          top: 0;
          z-index: 8; /* stylelint-disable-line declaration-property-value-blacklist */
        }
        .show-register-es .register-ctn {
            top: 1.06rem;
        }
    }
    &.c-quick-register-detail-fix {
        .zindex-translatez(@zindex-fixedregister,translate3d(0,0,@tranlatez-fixedregister));
        bottom: 1.39rem;
        padding-bottom: 0;
        background-color: #fff;
    }
    &.c-quick-register-footer {
        bottom: 50px;
    }
    .c-download-bar {
        > img {
            width: 100%;
        }
    }
    .quick__register-close{
      position: absolute;
      top: -.375rem;
      .right(.27rem);
      font-weight: 700;
      width: .75rem;
      height: .75rem;
      line-height: .75rem;
      background: #fff;
      border-radius: 50%;
      .zindex-translatez(@zindex-header,translate3d(0,0,@tranlatez-header));
    }
    .show-register {
        background: #53416D;
        .register {
          padding: .4rem .64rem 0;
          display: table;
          width: 100%;
          box-sizing: border-box;
        }

        &__loading {
          padding-top: .08rem;
          :deep(.sui-loading) {
            &__common-inner{
              margin: 0 auto;
              width: .48rem;
              height: .48rem;
            }
            
            &__circular-path {
              stroke: #fff;
            }
            
            &__circular-path-under{
              stroke: rgba(255, 255, 255, .16);
            }
          }
          

        }

        .qiuck-error {
            color: #ff696e;
            .txt-l();
            padding: .1rem 0 0;
        }
        .quick-text-error {
            color: #ff696e;
            .txt-l();
            padding: .12rem 0;
        }
        .reg-getoff {
            .font-dpr(36px);
            color: #fff;
            padding: .32rem 0 .48rem;
            font-weight: bold;
            > em {
                color: @color_green;
            }
        }
        .quick-loading-btn {
            display: flex;
            align-items: center;
            height: 100%;
            &>div {
                width: 0.25rem;
                height: 0.25rem;
            }
        }
    }
    .show-register-es {
        .register-ctn {
            position: absolute;
            top: .73rem;
            left: .8rem/*rtl:ignore*/;
            right: .8rem/*rtl:ignore*/;
            .c-privacy-check {
                .privacy-item {
                    .flexbox();
                    .txt-l();
                    margin: .2rem 0 0 0;
                    > label {
                        .margin-r(.2rem);
                    }
                }
            }
            .i-t-protocol {
                .txt-l();
               :deep(a) {
                    color: unset;
                }
            }
            .register-content {
                display: flex;
                text-align: center;
                > input {
                    background: transparent;
                    border: 1px solid #b8b8b8;
                    //color: #b8b8b8;
                    color: #222;
                    background-color: #fff;
                    width: 100%;
                    height: .64rem;
                    padding-top: 0;
                    padding-bottom: 0;
                    text-transform: none;
                    border-radius: 0;
                    box-sizing: border-box;
                    display: block;
                    width: 9.2rem;
                    padding: 0 .266667rem;
                    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
                    flex: 1;
                }
                > a {
                    background: #f08579;
                    color: #fff;
                    margin-left: .12rem/*rtl:ignore*/;
                    display: block;
                    min-width: 1.28rem;
                    height: .64rem;
                    line-height: .64rem;
                    border: none;
                    padding: 0 .1rem;
                    font-weight: 700;
                    text-decoration: none;
                    word-break: keep-all;
                }
                .qiuck-error{
                    color: #333 !important;
                }
            }
        }
    }
    .show-reg-suc {
        padding: .4rem;
        background: #53416D;
        .sign-up {
            .font-dpr(36px);
            color: #fff;
        }
        > p {
            color: #fff;
            > em {
                color: @color_green;
            }
        }
        > strong {
            color: @color_green;
            font-weight: bold;
            .font-dpr(36px);
        }
    }
    .show-register-small {
        display: flex;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0/*rtl:ignore*/;
        .align-center();
        padding: .24rem;
        min-height: 1.28rem;
        background-color: #53416D;
        .content {
          flex: 1;
          .margin-l(.16rem);
          .margin-r(.75rem);
          color: #fff;
          .font-dpr(28px);
          font-weight: bold;
          text-align: left/*rtl:ignore*/;
          // word-break: break-all;
        }
        .j-quick-register-btn{
            padding: .1rem .32rem;
            flex: 0 0 auto;
            border: none;
            text-decoration: none;
            min-width: 1.9rem;
        }
        .quick__register-close{
          position: static;
        }
    }
    &.c-quick-register-ar {
        &.c-quick-register-detail {
            height: 4.66rem;
        }
         .show-register {
            position: relative;
            height: 4rem;
            padding-top: .5rem;
        }
         .show-reg-suc {
            position: relative;
            height: 4rem;
            padding-top: 1rem;
        }
         svg {
            width: .6rem;
            height: .6rem;
            top: .15rem;
            .left(.1rem);
        }
    }

    &.is-new-mode {
        .quick__register-close{
          top: .05rem;
          .right(.05rem);
          width: .64rem;
          height: .64rem;
          line-height: .64rem;
          color: rgba(255, 255, 255, .8);
          background: rgba(0,0,0, .2);
          .font-dpr(20px);
        }
        &.c-quick-register-detail {
            height: auto;
            .quick__register-close{
              top: .54rem;
            }
        }
        .show-register-es {
            .register-ctn {
                position: static;
                padding: .32rem .50rem;
                .c-privacy-check {
                    .privacy-item {
                        height: auto;
                        .privacy {
                            line-height: .6rem;
                        }
                        &.ccc-config{
                            .privacy{
                              :deep(a){
                                    color: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
        .show-reg-suc {
            padding: 0;
        }
    }
}
.privacy-radio{
  height: 0.58rem !important;
  :deep(.sui-radio__check){
    width: 0.41rem !important;
    height: 0.41rem !important;
  }
}
.geetest_wind.geetest_panel {
    transform: translate3d(0,0, 2147483647px);
    direction: ltr/*rtl:ignore*/;
}
.geetest_wind.geetest_panel * {
    box-sizing: content-box;
}
@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}
</style>

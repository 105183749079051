import { preloadImgs } from '@shein/common-function'
import { scrollFix } from 'public/src/pages/common/utils/index.js'
/**
 * @param {object} vueInstance 弹窗vue实例
 * @param {boolean} isFixedBody 弹窗是否会锁定页面，scrollFix
 * @param {string} ctrlVariableName 控制弹窗显示隐藏的vue实例属性名
 * @param {array[string]} imgList 弹窗内部加载的图片，这些图片会导致LCP，需要传入
 * @param {string} extraStyle 隐藏弹窗时，需要的额外CSS，比如说隐藏蒙层的css
 */
export default class PopupAvoidLCP {
  static idx = 0
  constructor ({
    vueInstance,
    isFixedBody = false,
    ctrlVariableName,
    imgList = [],
    extraStyle = ''
  }) {
    this.vueInstance = vueInstance
    this.imgList = imgList
    this.isFixedBody = isFixedBody
    this.extraStyle = '.lcp-el{transform:translate3d(750px, 0, 0)!important}' + extraStyle
    this.unwatch = this.vueInstance.$watch(ctrlVariableName, newVal => {
      if (newVal) this.start()
    }, { immediate: true })
  }
  destroy () {
    this.vueInstance = undefined
    this.unwatch = undefined 
  }
  start () {
    this.preset()
    if (this.imgList instanceof Array && this.imgList.length > 0) {
      preloadImgs({ imgs: this.imgList }).then(() => {
        this.effect()
      })
    } else {
      this.effect()
    }
  }

  preset () {
    // 下一个宏任务取消页面fixed，保证当前弹窗的fixed已执行
    if (this.isFixedBody) {
      this.scroll_fix = scrollFix()
      setTimeout(() => {
        this.scroll_fix(false)
      }, 50)
    }
    if (this.extraStyle) {
      this.addStyle()
    }
  }

  effect () {
    setTimeout(() => {
      if (this.isFixedBody) {
        this.scroll_fix(true)
      }
      if (this.extraStyle) {
        this.removeStyle()
      }
      if (this.vueInstance && Object.prototype.hasOwnProperty.call(this.vueInstance, 'popupAvoidLCPLoaded')) {
        this.vueInstance['popupAvoidLCPLoaded'] = true
      }
      this.unwatch()
    }, 100) // 100ms留给浏览器的渲染时间
  }

  addStyle () {
    this.index = PopupAvoidLCP.idx++
    const css = document.createElement('style')
    css.id = 'avoidLCPStyle' + this.index
    css.type = 'text/css'
    if (css.styleSheet) {
      css.styleSheet.cssText = this.extraStyle
    } else {
      css.appendChild(document.createTextNode(this.extraStyle))
    }
    document.getElementsByTagName('head')[0].appendChild(css)
  }

  removeStyle () {
    const id = '#' + 'avoidLCPStyle' + this.index
    const styleDom = document.querySelector(id)
    styleDom && styleDom.remove()
  }
}

import apiReport from '../../../apiReport/index'

const defaultCache = {
  // 登录成功
  login: { timestap: null, times: 0, max: 2 },
  geetest: { timestap: null, times: 0, max: 2 },
  thirdClick_facebook: { timestap: null, times: 0,  max: 3 },
  thirdClick_google: { timestap: null, times: 0,  max: 3 },
  thirdClick_vk: { timestap: null, times: 0,  max: 3 },
}

class ExceptionReport {
  
  key = 'exceptionReport'
  limitTime = 60000

  cache = {}

  constructor () {
    this.cache = this.getCache()
  }

  getCache () {
    try {
      return JSON.parse(sessionStorage.getItem(this.key)) || defaultCache
    } catch (e) {
      return {}
    }
  } 

  setCache () {
    try {
      sessionStorage.setItem(this.key, JSON.stringify(this.cache))
    } catch (e) {
      // empty
    }
  }

  setTypeCache (type, timestap, times) {
    this.cache[type].timestap = timestap
    this.cache[type].times = times
    this.setCache()
  }

  login (data = {}) {
    const { from = '' } = data
    const { login = {} } = this.cache
    const { timestap, times, max } = login
    const nowTime = Date.now()
    // 大于一分钟，重置次数
    if (nowTime - timestap > this.limitTime) return this.setTypeCache('login', nowTime, 1)

    const nowTimes = times + 1
    this.setTypeCache('login', timestap, nowTimes)
    if (nowTimes < max) return

    apiReport.report({
      status_code: 200,
      errorType: 'loginException',
      errorSubType: 'login',
      errorFrom: from,
      errorTimes: times,
    })
  }

  geetest (data = {}) {
    const { from = '' } = data
    const { geetest = {} } = this.cache
    const { timestap, times, max } = geetest
    const nowTime = Date.now()
    // 大于一分钟，重置次数
    if (nowTime - timestap > this.limitTime) return this.setTypeCache('geetest', nowTime, 1)
    
    const nowTimes = times + 1
    this.setTypeCache('geetest', timestap, nowTimes)
    if (nowTimes < max) return

    apiReport.report({
      status_code: 200,
      errorType: 'loginException',
      errorSubType: 'geetest',
      errorFrom: from,
      errorTimes: times,
    })
  }

  thirdClick (params) {
    const { from = '' } = params
    const typeName = `thirdClick_${from}`
    const data = this.cache[typeName] || null
    if (!data) return

    const { timestap, max, times } = data
    const nowTime = Date.now()

    // 大于一分钟，重置次数
    if (nowTime - timestap > this.limitTime) return this.setTypeCache(typeName, nowTime, 1)
    
    const nowTimes = times + 1
    this.setTypeCache(typeName, timestap, nowTimes)
    if (nowTimes < max) return 

    apiReport.report({
      status_code: 200,
      errorType: 'loginException',
      errorSubType: 'thirdClick',
      errorFrom: from,
      errorTimes: times,
    })
  }
}

function createInit () {
  if (typeof window == 'undefined') return {}
  const exceptionReport = new ExceptionReport()
  window.exceptionReport = exceptionReport
  return exceptionReport
}

export default createInit()

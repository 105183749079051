const GB_bi_register = {
  biClickEvent: function (type, privacy_Unchecked) {
    sa('send', {
      activity_name: 'click_quick_register',
      activity_param: {
        activity_from: 'quick',
        quick_type: type,
        privacy_Unchecked
      }
    })
  },
  biExposeEvent: function (type) {
    sa('send', {
      activity_name: 'expose_quick_register',
      activity_param: {
        activity_from: 'quick',
        quick_type: type
      }
    })
  },
  biRegisterResult: function (status, reason, type) {
    var privacy
    let privacyElements = document.querySelectorAll('.j-privacy-check-register')
    privacyElements.forEach(privacyElement => {
      let style = getComputedStyle(privacyElement)
      let isVisible = style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0'

      let privacyCheckbox = privacyElement.querySelector('.j-privacy-checkbox')
      if (isVisible && privacyCheckbox && privacyCheckbox.checked) {
        privacy = 1
      }else {
        privacy = 0
      }
    })
    var sadata_result = {
      activity_name: 'click_quick_register_result',
      activity_param: {
        privacy_policy: privacy,
        status: status,
        reason: reason,
        activity_from: 'quick',
        quick_type: type
      }
    }
    sa('send', sadata_result)
  },
  // 关闭快速曝光弹窗  当弹窗正常展开发show, 当弹窗收起发hide
  biCloseQuickRegister: function (type) {
    sa('send', {
      activity_name: 'click_quick_register_close',
      activity_param: {
        quick_type: type
      }
    })
  }
}

export default GB_bi_register

import schttp from 'public/src/services/schttp/index'


async function requestPromise(type, url, params, headers = {}) {
  const method = type.toLowerCase()
  const options = {
    method,
    url,
    headers: { ...headers }
  }
  if (method == 'get') options.params = params
  else options.data = params

  const data = await schttp(options).catch((err) => ({ code: -1, msg: err.message }))

  return data
}

export async function request(type, url, params, headers) {
  const data = await requestPromise(type, url, params, headers)
  return data
}

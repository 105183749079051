import { fetchGoogleRecaptcha, startFacebookSdk } from '../util'
import schttp from 'public/src/services/schttp'


// 初始化3方登陆 SDK js
export function initThirdLoginLibs () {
  startFacebookSdk()
  fetchGoogleRecaptcha()
}

export const initNeedsLibs = async () => {
  initThirdLoginLibs()
  const { loadTdLibJs } = await import('public/src/pages/common/RiskCommon/libs/BlackBox/index')
  loadTdLibJs()
  import('../../components/switchSite/index')
  import('public/src/pages/login/common/initFtoken')
  import('public/src/pages/common/RiskCommon/libs/ExceptionReport/index')
  setTimeout(async () => {
    window.LoginRiskVerifyInstance = await initRiskVerify()
  }, 0)
}

export const initThirdLoginSdk = (val) => {
  if(val == 11){
    startFacebookSdk()
    return
  }
}

export const initRiskVerify = async () => {
  if(window?.LoginRiskVerifyInstance){
    return window.LoginRiskVerifyInstance
  }
  const riskVerify = (await import('@shein-risk/si_verify'))?.default
  riskVerify.init({
    axiosInstance: schttp, 
    axiosConfig: {
      headers: {},
    },
  })
  // eslint-disable-next-line require-atomic-updates
  window.LoginRiskVerifyInstance = riskVerify
  return riskVerify
}

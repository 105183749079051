import { request } from 'public/src/pages/login/services/index.js'
import { commonDealWith } from '@login/service.js'

export async function quickRegister(params) {
  const data = request('post', `/api/auth/quickRegister/create`, params)
  return data
}


export async function quickRegisterSer (params) {
  params.blackbox = window._fmOpt?.__blackbox || '' // 同盾
  const data = await request('post', `/api/auth/quickRegister/create`, params)
  commonDealWith(data)
  return data
}

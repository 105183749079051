import { SToast } from '@shein-aidc/sui-toast/mobile'
import RiskValidte from '../components/RiskValidte.vue'
import analysisIndex from '@login/analysis/index'
const { sensorsSend } = analysisIndex

export default {
  components: { RiskValidte },
  methods: {
    /**
     * 检测返回结果是否存在风控、有则进行风控流程
     * @param {Object} response 登陆注册返回的响应结果
     * @param {Object} options 来源配置
     * @param {Function} cb 回调函数
     * @returns {Boolean} 布尔值，true:有风险，拦截。false: 无风险，结束拦截往下走流程
     */
    detectRiskChalleage(response, options, cb) {
      const { code, tips } = response
      const { riskCode } = options

      const errorCodes = [
        '402916',
        '402918',
        '402915',
        '402917',
        '402919',
        '460608',
        '460609',
        '460610',
        '460611'
      ]

      if (this.$refs.RiskValidate?.info?.show) {
        // 埋点
        sensorsSend('2-16-4', {
          result: errorCodes.includes(code) ? '1' : '0',
          scene: options.bi || options.scene || ''
        })
      }

      // 登陆/注册成功，隐藏弹窗
      if (code == 0) {
        if (this.$refs.RiskValidate?.info?.show)
          this.$refs.RiskValidate.updateRiskInfo({ show: false })
        return false
      }
      // 已展示的风控验证码弹窗，并且返回当前错误码，toast提示
      if (errorCodes.includes(code?.toString()) && this.$refs.RiskValidate?.info?.show) {
        this.$refs.RiskValidate.updateRiskInfo({ loading: false })
        SToast(tips)
        return true
      }
      if (this.$refs.RiskValidate?.info?.show)
        this.$refs.RiskValidate.updateRiskInfo({ show: false })
      // 风控验证
      if (code == 402906 || code == riskCode) {
        return this.runRiskChalleage(options, response, cb)
      }
    },
    /**
     * 执行分控挑战
     * @param {Object} options 登陆/注册 接口的请求参数
     * @param {Object} response 登陆/注册 接口响应结果
     * @returns {Boolean} 布尔值，true:有风险，拦截。false: 无风险，结束拦截往下走流程
     */
    runRiskChalleage(options, response, cb) {
      const { unlogin, params, message_type, scene, bi, codeUrl } = options
      const { info } = response
      const { extend_info = {}, risk_id } = info.risk_info || info || {}
      const { actions = [] } = extend_info
      const actionName = actions[0]?.name || ''

      const singleChalleage = actions?.length == 1
      // 手机短信验证
      if (actionName == 'phone_captcha' && singleChalleage) {
        const aliasMark = actions[0]?.param?.phone || params.alias
        const alias = params.alias
        const area_code = params.area_code
        const encrypt_phone = actions[0]?.param?.encrypt_phone || ''
        const riskParms = {
          unlogin,
          risk_id,
          show: true,
          aliasMark,
          area_code,
          alias,
          channel: 'phone_msg',
          message_type,
          scene,
          bi,
          encrypt_phone,
          codeUrl
        }
        this.$refs.RiskValidate.updateRiskInfo(riskParms, ({ code, type }) => {
          params.risk_id = risk_id
          params.risk_verification_code = code
          cb({ params, type })
        })
        return true
      }
      // 邮箱验证码验证
      if (actionName == 'email_captcha' && singleChalleage) {
        const aliasMark = actions[0]?.param?.email || params.email
        const alias = params.email || actions[0]?.param?.email
        const encrypt_email = actions[0]?.param?.encrypt_email || ''
        const riskParms = {
          unlogin,
          risk_id,
          show: true,
          aliasMark,
          alias,
          channel: 'mmp_email',
          message_type,
          scene,
          bi,
          encrypt_email,
          codeUrl
        }
        this.$refs.RiskValidate.updateRiskInfo(riskParms, ({ code, type }) => {
          params.risk_id = risk_id
          params.risk_verification_code = code
          cb({ params, type })
        })
        return true
      }

      const { phoneCaptcha, emailCaptcha, forcePwd, phoneAliasMark, emailAliasMark, encryptPhoneMark, encryptEmailMark, encryptPhoneAlias } =
        this.formatDetectActions(actions)

      // 手机验证码 & 强制修改密码
      if (phoneCaptcha && forcePwd) {
        const aliasMark = phoneAliasMark || params.alias
        const alias = params.alias
        const area_code = params.area_code
        const encrypt_phone = encryptPhoneMark
        const encrypt_phone_alias = encryptPhoneAlias
        const riskParms = {
          unlogin,
          risk_id,
          aliasMark,
          alias,
          area_code,
          channel: 'phone_msg',
          message_type,
          forcePwd: true,
          scene,
          show: true,
          encrypt_phone,
          encrypt_phone_alias,
          bi,
          codeUrl
        }
        this.$refs.RiskValidate.updateRiskInfo(riskParms, ({ type }) => {
          cb({ type })
        })
        return true
      }

      // 邮箱验证码 & 强制修改密码
      if (emailCaptcha && forcePwd) {
        const aliasMark = emailAliasMark || params.email
        const alias = params.email
        const encrypt_email = encryptEmailMark
        const riskParms = {
          unlogin,
          risk_id,
          aliasMark,
          alias,
          channel: 'mmp_email',
          message_type,
          forcePwd: true,
          scene,
          show: true,
          encrypt_email,
          bi,
          codeUrl
        }
        this.$refs.RiskValidate.updateRiskInfo(riskParms, ({ type }) => {
          cb({ type })
        })
        return true
      }

      return false
    },
    /**
     * 格式化风控返回的actions挑战
     * @param {object} actions 风控返回的actions
     * @returns phoneCaptcha: 手机验证码， emailCaptcha: 邮箱验证码，forcePwd： 强制更新密码，phoneAliasMark：脱敏手机号，emailAliasMark： 脱敏邮箱
     */
    formatDetectActions(actions) {
      let phoneCaptcha = false // 手机验证码
      let emailCaptcha = false // 邮箱验证码
      let forcePwd = false // 强制修改密码
      let phoneAliasMark = ''
      let emailAliasMark = ''
      let encryptPhoneMark = ''
      let encryptEmailMark = ''
      let encryptPhoneAlias = ''

      actions.forEach(v => {
        if (v.name == 'phone_captcha') {
          phoneCaptcha = true
          phoneAliasMark = v.param?.phone || ''
          encryptPhoneMark = v.param?.encrypt_phone || ''
          encryptPhoneAlias = v.param?.encrypt_phone_alias || ''
        } else if (v.name == 'email_captcha') {
          emailCaptcha = true
          emailAliasMark = v.param?.email || ''
          encryptEmailMark = v.param?.encrypt_email || ''
        } else if (v.name == 'change_pwd_blasting') forcePwd = true
      })
      return { phoneCaptcha, emailCaptcha, forcePwd, phoneAliasMark, emailAliasMark, encryptPhoneMark, encryptEmailMark, encryptPhoneAlias }
    }
  }
}

import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '2-8' })

const GB_ga_register = {
  register: function (val, failMsg) {
    var statusLabel = val ? 'Success' : 'Fail_'
    daEventCenter.triggerNotice({ 
      daId: '2-8-3', 
      extraData: {
        register_method: 'email',
        scenes: '快速弹窗',
        is_success: !!val,
        fail_reason: val ? '' : `${statusLabel}${failMsg}`
      }
    })
  },
  autoPopUps: function () {
    daEventCenter.triggerNotice({ 
      daId: '2-8-1', 
      extraData: {
        scenes: '快速弹窗',
        autoPopUps: true
      }
    })
  },
  closePopUps: function () {
    daEventCenter.triggerNotice({ 
      daId: '2-8-4', 
      extraData: {
        scenes: '快速弹窗'
      }
    })
  },
  // 击收起弹窗中的Register按钮，展开快速注册弹窗
  openPopUps: function () {
    daEventCenter.triggerNotice({ 
      daId: '2-8-1', 
      extraData: {
        scenes: '快速弹窗'
      }
    })
  },
  editEmail () {
    daEventCenter.triggerNotice({ 
      daId: '2-8-6', 
      extraData: {
        scenes: '快速弹窗',
        tab_content: 'Reigister'
      }
    })
  }
}

export default GB_ga_register

<template>
  <div
    v-if="SiteUID != 'mkr'" 
    class="quick__register-subscribe"
  >
    <SCheckbox 
      v-model="isCheckSubscribe"
      @change="handleSubscribeChange"
    />
    <div
      class="subscribe-text"
      :style="{color: textColor}"
    >
      {{ subscribeText }}
    </div>
  </div>
</template>

<script setup>
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { ref, defineProps, computed } from 'vue'
import { template } from '@shein/common-function'
const { SiteUID } = gbCommonInfo

const props = defineProps({
  langText: {
    type: Object,
    default: () => {}
  },
  textColor: {
    type: String,
    default: '#000'
  }
})

const emit = defineEmits(['change'])

const isCheckSubscribe = ref(false)

const subscribeText = computed(() => {
  const str = props.langText?.SHEIN_KEY_PWA_33734
  return template('SHEIN', 'SHEIN', str)
})


const handleSubscribeChange = () => {
  emit('change', isCheckSubscribe.value)
}

// eslint-disable-next-line semi
;
</script>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuickRegisterSubscribe',
})
</script>

<style lang="less" scoped>
/* stylelint-disable declaration-no-important */
.quick__register-subscribe{
  display: flex;
  margin-top: 0.1rem;
  :deep(.sui-checkbox__label-select){
    width: 0.41rem !important;
    height: 0.41rem !important;
  }
  :deep(.sui-checkbox){
    margin-right: 0.18rem !important;
    flex-direction: column !important;
  }
  .subscribe-text{
    text-align: left;
    font-size: 12px;
    color: @sui_color_brand;
  }
}
</style>
